import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ViewBtn from "../../Buttons/ViewBtn";
import { Form, Select, Tag, Modal } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { loadAllTicketPaginated } from "../../../redux/rtk/features/crm/ticket/ticketSlice";
import { loadAllTicketStatus } from "../../../redux/rtk/features/crm/ticketStatus/ticketStatusSlice";
import getRoleFromToken from "../../../utils/getRoleFromToken";
import CrmSearch from "../CommonUi/CrmSearch";
import TableComponent from "./TableComponent";
import TicketStatusUpdate from "./TicketDetails/TicketStatusUpdate";
import baseUrl from "../../../utils/baseUrl";
dayjs.extend(relativeTime);

const Ticket = () => {
  const dispatch = useDispatch();
  const [isFilter, setFilter] = useState(false);
  const { Option } = Select;
  const filterToggle = () => setFilter((prev) => !prev);

  const { list: ticketList, loading, total } = useSelector((state) => state.ticket);
  const { list: crmTicketStatusList } = useSelector((state) => state.ticketStatus);

  const role = getRoleFromToken();
  const [form] = Form.useForm();
  
  // State for image preview
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  // Function to handle image preview
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewVisible(true);
  };

  useEffect(() => {
    dispatch(loadAllTicketPaginated({ query: "all" }));
    dispatch(loadAllTicketStatus());
  }, [dispatch]);

  const columns = [
    {
      title: "User Image",
      key: "userImage",
      dataIndex: "images",
      render: (images) => {
        if (images) {
          const userImage = images.find((img) => img.imageType === "userImage");
          if (userImage) {
            const imageUrl = `${baseUrl}/files/${userImage.imageName}`;
            return <img src={imageUrl} alt="User" className="w-12 h-12 object-cover" />;
          }
        }
        return <span>No Image</span>;
      },
    },
    
    {
      title: "Entry Ticket ID",
      key: "ticketId",
      dataIndex: "ticketId",
    },
    {
      title: "Alumni Id",
      key: "postFor",
      dataIndex: "customer",
      render: (customer) => {
        try {
          if (customer && customer.alumnijson) {
            const alumniData = JSON.parse(customer.alumnijson);
            return alumniData.Alumni_StudID_Str || "N/A";
          }
          return "No Alumni ID";
        } catch (error) {
          console.error("Error parsing alumnijson:", error, customer);
          return "Invalid Data";
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Alumni Name",
      key: "customer",
      dataIndex: "customer",
      render: (customer) => {
        try {
          if (customer && customer.alumnijson) {
            const alumniData = JSON.parse(customer.alumnijson);
            const fullName = `${alumniData.Alumni_FName_Str} ${alumniData.Alumni_LName_Str}`;
            return <Tag color="green">{fullName}</Tag>;
          }
          return <Tag color="green">No Name Available</Tag>;
        } catch (error) {
          console.error("Error parsing alumnijson:", error, customer);
          return <Tag color="red">Invalid Data</Tag>;
        }
      },
    },
    {
      title: "Ticket Status",
      dataIndex: "ticketStatus",
      key: "ticketStatus",
      render: (ticketStatus) => <Tag color="red">{ticketStatus?.ticketStatusName}</Tag>,
    },
    {
      title: "Voucher ID",
      key: "voucherId",
      dataIndex: "voucherId",
    },
    {
      title: "Voucher Image",
      key: "voucherImage",
      dataIndex: "images",
      render: (images) => {
        if (images) {
          const voucherImage = images.find((img) => img.imageType === "voucherImage");
          if (voucherImage) {
            const imageUrl = `${baseUrl}/files/${voucherImage.imageName}`;
            return (
              <img
                src={imageUrl}
                alt="Voucher"
                className="w-12 h-12 object-cover cursor-pointer"
                onClick={() => handlePreview(imageUrl)}
              />
            );
          }
        }
        return <span>No Image</span>;
      },
    },
    ...(role === "admin"
      ? [
          {
            title: "Action",
            dataIndex: "ticketId",
            key: "action",
            render: (ticketId) => (
              <div className="flex justify-start gap-2">
                <TicketStatusUpdate ticketId={ticketId} />
              </div>
            ),
          },
        ]
      : []),
  ];

  const onFinish = (values) => {
    dispatch(loadAllTicketPaginated(values));  
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="container">
      <div className="flex justify-center items-center mt-5 mb-8">
      <div style={{ textAlign: 'center', fontSize: '2.5em', fontWeight: 'bold' }}>
     <h1>UMT 27th Convocation PRS Entry System</h1>
</div>
      </div>

      <div className="bg-white dark:bg-DTableBg flex flex-col md:flex-row justify-between items-center gap-3 p-3 px-6 border">
        <div className="flex justify-end items-center gap-5">
          {!isFilter ? (
            <CrmSearch paginatedThunk={loadAllTicketPaginated} />
          ) : (
            <Form
              className="flex flex-wrap justify-center gap-2 md:items-center"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="inline"
            >
              <Form.Item name="ticketStatus" className="w-full md:w-[180px]">
                <Select mode="multiple" style={{ width: "100%" }} placeholder="Select Ticket Status">
                  {crmTicketStatusList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.ticketStatusName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <button className="infineneBtn text-white px-5 py-2 rounded cursor-pointer" type="submit">
                Filter
              </button>
              <button
                onClick={filterToggle}
                className="px-5 py-2 rounded cursor-pointer ml-2 text-rose-700 border border-rose-500"
              >
                Cancel
              </button>
            </Form>
          )}
        </div>
      </div>

      <TableComponent
        total={total}
        role={role}
        paginatedThunk={loadAllTicketPaginated}
        columns={columns}
        list={ticketList}
        loading={loading}
        
      />
     

      {/* Image Preview Modal */}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        centered
      >
        <img src={previewImage} alt="Preview" className="w-full h-auto" />
      </Modal>
    </div>
  );
};

export default Ticket;
