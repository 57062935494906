import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
	list: [],
	customerList: [],
	user: null,
	error: "",
	loading: false,
};



export const loadAllCustomers = createAsyncThunk(
	"user/loadAllCustomers",
	async () => {
		try {
			const { data } = await axios.get(`customer?query=all`);
			return data; // Assuming the response data is an array of customers
		} catch (error) {
			console.log(error.message);
			return Promise.reject(error.message);
		}
	}
);

export const loadSingleCustomer = createAsyncThunk(
	"user/loadSingleCustomer",
	async (id) => {
		try {
			const { data } = await axios.get(`customer/${id}`);
			return data; // Assuming the response data is a single customer object
		} catch (error) {
			console.log(error.message);
			return Promise.reject(error.message);
		}
	}
);


export const updateCustomerStatus = createAsyncThunk(
	"user/updateCustomerStatus",
	async ({ apiUrl, id, status }, { rejectWithValue }) => {
	  try {
		const response = await axios({
		  method: "patch",
		  headers: {
			Accept: "application/json",
			"Content-Type": "application/json;charset=UTF-8",
		  },
		  url: apiUrl,
		});
		toast.success(`User has been ${status ? "activated" : "deactivated"} successfully.`);
		return {
		  id,
		  status,
		  ...response.data, // Assuming response.data contains updated user data
		};
	  } catch (error) {
		console.log(error.message);
		toast.error("Error updating user status. Please try again.");
		return rejectWithValue(error.message);
	  }
	}
  );


export const addStaff = createAsyncThunk("user/addStaff", async (values) => {
	try {
		const { data } = await axios({
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `user/register`,
			data: {
				...values,
			},
		});
		toast.success("Registration successful");
		return {
			data,
			message: "success",
		};
	} catch (error) {
		toast.error("Error in adding staff try again");
		console.log(error.message);
		return {
			message: "error",
		};
	}
});

// export const deleteStaff = createAsyncThunk("user/deleteStaff", async (id) => {
// 	try {
// 		const resp = await axios({
// 			method: "patch",
// 			headers: {
// 				Accept: "application/json",
// 				"Content-Type": "application/json;charset=UTF-8",
// 			},
// 			url: `user/${id}`,
// 			data: {
// 				status: "false",
// 			},
// 		});
// 		return resp.data;
// 	} catch (error) {
// 		console.log(error.message);
// 	}
// });

export const loadSingleStaff = createAsyncThunk(
	"user/loadSingleStaff",
	async (id) => {
		try {
			const data = await axios.get(`user/${id}`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const loadAllStaff = createAsyncThunk(
	"user/loadAllStaff",
	async ({ status }) => {
		try {
			const { data } = await axios.get(`user?status=${status}`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const addUser = createAsyncThunk("user/addUser", async (values) => {
	try {
		const { data } = await axios({
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `user/login`,
			data: {
				...values,
			},
		});
		localStorage.setItem("access-token", data.token);
		localStorage.setItem("role", data.role);
		localStorage.setItem("user", data.userName);
		localStorage.setItem("id", data.id);
		localStorage.setItem("isLogged", true);
		toast.success(" Login Successfully Done");

		return {
			data,
			message: "success",
		};
	} catch (error) {
		console.log(error.message);
		toast.error("Incorrect Username or Password !");
		return {
			message: "error",
		};
	}
});

// Add Customer Login

export const addCustomer = createAsyncThunk(
	"user/addCustomer",
	async (values) => {
		try {
			const { data } = await axios({
				method: "post",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `customer/login`,
				data: {
					...values,
				},
			});
			localStorage.setItem("access-token", data.token);
			localStorage.setItem("email", data.email);
			localStorage.setItem("fullName", data.firstName + " " + data.lastName);
			localStorage.setItem("id", data.id);
			localStorage.setItem("isLogged", true);
			toast.success(" Login Successfully Done");

			return {
				data,
				message: "success",
			};
		} catch (error) {
			// Handle specific error responses from the server
			if (error.response && error.response.data.message) {
				const serverMessage = error.response.data.message;

				// Show appropriate message based on the server response
				if (serverMessage === "Account is inactive") {
					toast.error("Your Account is inactive. Please contact admin.");
				} else if (serverMessage === "Email or password is incorrect") {
					toast.error("Incorrect Email or Password!");
				}
			} else {
				// Handle other unexpected errors
				toast.error("An error occurred during login.");
			}

			return {
				message: "error",
			};
		}
	}
);

//Customer Registration

export const customerRegister = createAsyncThunk(
	"user/customerRegister",
	async (values, { rejectWithValue }) => {
	  try {
		const { data } = await axios({
		  method: "post",
		  headers: {
			Accept: "application/json",
			"Content-Type": "application/json;charset=UTF-8",
		  },
		  url: `customer/register`,
		  data: {
			...values,  // Spread the form values, including userType
		  },
		});
  
		// Assuming the backend returns a message in case of success
		toast.success("Registration successful");
		return {
		  message: "success",
		  data,
		};
	  } catch (error) {
		// Handle specific backend error messages (like 'Email already registered')
		if (error.response && error.response.data && error.response.data.message) {
		  const errorMessage = error.response.data.message;
		  toast.error(errorMessage); // Show specific error message from backend
		  return rejectWithValue({ message: errorMessage });
		}
  
		// Fallback generic error message
		toast.error("Error in adding customer, try again.");
		return rejectWithValue({ message: "error" });
	  }
	}
  );
//Customer forget password

export const customerForgetPassword = createAsyncThunk(
	"user/customerForgetPassword",
	async (values) => {
		try {
			const { data } = await axios({
				method: "patch",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `customer/forgot-password`,
				data: {
					...values,
				},
			});
			toast.success("Password reset successful");
			return {
				data,
				message: "success",
			};
		} catch (error) {
			toast.error("Error in password reset try again");
			return {
				message: "error",
			};
		}
	}
);



//Admin forget password

export const adminForgetPassword = createAsyncThunk(
	"user/adminForgetPassword",
	async (values) => {
		try {
			const { data } = await axios({
				method: "patch",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `customer/forgot-passwordd`,
				data: {
					...values,
				},
			});
			toast.success("Password reset successful");
			return {
				data,
				message: "success",
			};
		} catch (error) {
			toast.error("Error in password reset try again");
			return {
				message: "error",
			};
		}
	}
);


// Update User Information

export const updateUser = createAsyncThunk(
	"user/updateUser",
	async ({ id, values }) => {
		try {
			const { data } = await axios({
				method: "put",
				url: `user/${id}`,
				data: {
					...values,
				},
			});
			toast.success("User Information Updated Successfully");
			return {
				data,
				message: "success",
			};
		} catch (error) {
			console.log(error.message);
			toast.error("Error in updating user information");
			return {
				message: "error",
			};
		}
	}
);


const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		clearUser: (state) => {
			state.user = null;
		},
	},
	extraReducers: (builder) => {

		// Load All Customers
		builder.addCase(loadAllCustomers.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(loadAllCustomers.fulfilled, (state, action) => {
			state.loading = false;
			state.customerList = action.payload;
		});
		builder.addCase(loadAllCustomers.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});

	

		// Load Single Customer
		builder.addCase(loadSingleCustomer.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(loadSingleCustomer.fulfilled, (state, action) => {
			state.loading = false;
			state.user = action.payload;
		});
		builder.addCase(loadSingleCustomer.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});




		// 1) ====== builders for loadAllStaff ======

		builder.addCase(loadAllStaff.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadAllStaff.fulfilled, (state, action) => {
			state.loading = false;
			state.list = action.payload;
		});

		builder.addCase(loadAllStaff.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 2) ====== builders for addStaff ======

		builder.addCase(addStaff.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addStaff.fulfilled, (state, action) => {
			state.loading = false;
		});

		builder.addCase(addStaff.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for addUser ======

		builder.addCase(addUser.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addUser.fulfilled, (state, action) => {
			state.loading = false;

			if (!Array.isArray(state.user)) {
				state.user = [];
			}
			const user = [...state.user];
			user.push(action.payload.data);
			state.user = user;
		});

		builder.addCase(addUser.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for addCustomer ======

		builder.addCase(addCustomer.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addCustomer.fulfilled, (state, action) => {
			state.loading = false;

			if (!Array.isArray(state.user)) {
				state.user = [];
			}
			const user = [...state.user];
			user.push(action.payload.data);
			state.user = user;
		});

		builder.addCase(addCustomer.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for customerRegister ======

		builder.addCase(customerRegister.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(customerRegister.fulfilled, (state, action) => {
			state.loading = false;

			if (!Array.isArray(state.user)) {
				state.user = [];
			}
			const user = [...state.user];
			user.push(action.payload.data);
			state.user = user;
		});

		builder.addCase(customerRegister.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
		// 3) ====== builders for customerForgetPassword ======

		builder.addCase(customerForgetPassword.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(customerForgetPassword.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});

		builder.addCase(customerForgetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for adminForgetPassword ======

		builder.addCase(adminForgetPassword.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(adminForgetPassword.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});

		builder.addCase(adminForgetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});


		// 4) ====== builders for updateUser ======

		builder.addCase(updateUser.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(updateUser.fulfilled, (state, action) => {
			state.loading = false;
		});

		builder.addCase(updateUser.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 4) ====== builders for loadSingleStaff ======

		builder.addCase(loadSingleStaff.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadSingleStaff.fulfilled, (state, action) => {
			state.loading = false;

			state.user = action.payload.data;
		});

		builder.addCase(loadSingleStaff.rejected, (state, action) => {
			state.loading = false;
			console.log(action.payload);
			state.error = action.payload.message;
		});

		// 5) ====== builders for deleteStaff ======

		// builder.addCase(deleteStaff.pending, (state) => {
		// 	state.loading = true;
		// });

		// builder.addCase(deleteStaff.fulfilled, (state, action) => {
		// 	state.loading = false;

		// 	const filterUser = state.list.filter(
		// 		(staff) => staff.id !== parseInt(action.payload) && staff
		// 	);

		// 	state.list = filterUser;
		// });

		// builder.addCase(deleteStaff.rejected, (state, action) => {
		// 	state.loading = false;
		// 	state.error = action.payload.message;
		// });

		
	  
	},
});

export default userSlice.reducer;
export const { clearUser } = userSlice.actions;
