import { useEffect } from "react";
import { toast } from "react-toastify";
import getRoleFromToken from "../../utils/getRoleFromToken";

function Logout(props) {
	useEffect(() => {
		const role = getRoleFromToken();  // Move this inside useEffect to avoid the warning
		localStorage.clear();
		toast.success("Logged Out");

		localStorage.setItem("isLogged", "");

		if (role === "customer") {
			window.location.href = "/customer/login";
		} else {
			window.location.href = "/admin/auth/login";
		}
	}, []);  // No need to add 'role' as a dependency now
}

export default Logout;
