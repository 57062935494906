import { Affix, Layout } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import QuickLinks from "./QuickLinks";
import getRoleFromToken from "../../utils/getRoleFromToken";

const { Header: AntHeader, Content } = Layout;

function Main({ children }) {
	const [collapsed, setCollapsed] = useState(false);
	const [sideNavOpenKeys, setSideNavOpenKeys] = useState("");
	const [visible, setVisible] = useState(false);
	const [fixed, setFixed] = useState(false);
	const role = getRoleFromToken();

	const sideNavOpenKeysHandler = (val) => {
		setSideNavOpenKeys(val);
	};

	const handleCollapsed = (val) => {
		setCollapsed(val);
	};

	const openDrawer = () => setVisible(!visible);

	const handleFixedNavbar = (type) => setFixed(type);

	let { pathname } = useLocation();
	pathname = pathname.replace("/", " ");

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// Logic to hide the header on the Rssfeed route
	const shouldHideHeader = pathname.trim() === "";

	return (
		<div className='min-h-screen min-w-full'>
			<Layout className='layout'>
				{!shouldHideHeader && ( // Conditionally render the header
					fixed ? (
						<Affix>
							<AntHeader>
								<Header
									onPress={openDrawer}
									name={pathname}
									subName={pathname}
									handleFixedNavbar={handleFixedNavbar}
									collapsed={collapsed}
									handleCollapsed={handleCollapsed}
									isLogged={isLogged}
								/>
							</AntHeader>
						</Affix>
					) : (
						<AntHeader>
							<Header
								onPress={openDrawer}
								name={pathname}
								subName={pathname}
								handleFixedNavbar={handleFixedNavbar}
								collapsed={collapsed}
								handleCollapsed={handleCollapsed}
							/>
						</AntHeader>
					)
				)}
				{isLogged &&
					(pathname.trim() === "dashboard" || pathname.trim() === "") && (
						<QuickLinks
							sideNavOpenKeys={sideNavOpenKeys}
							sideNavOpenKeysHandler={sideNavOpenKeysHandler}
						/>
					)}
				<Content className=''>
					{role !== "customer" && (
						<div className=''>
							{/* Breadcrumb or Navigation Controls can go here */}
						</div>
					)}
					<Content>{children}</Content>
				</Content>
			</Layout>
		</div>
	);
}

export default Main;
