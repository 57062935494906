import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Select, Input } from "antd";
import { EditFilled } from "@ant-design/icons";
import { updateTicket, loadSingleTicket } from "../../../../redux/rtk/features/crm/ticket/ticketSlice";
import { loadAllTicketStatus } from "../../../../redux/rtk/features/crm/ticketStatus/ticketStatusSlice";
import UserPrivateComponent from "../../../PrivateRoutes/UserPrivateComponent";

const TicketStatusUpdate = ({ ticketId }) => {
  const [open, setOpen] = useState(false);
  const [ticketStatusId, setTicketStatusId] = useState("");
  const [rejectionReason, setRejectionReason] = useState(""); // New state for rejection reason
  const [otherReason, setOtherReason] = useState(""); // New state for custom reason input (for "Other")
  const dispatch = useDispatch();

  const { ticket, loading } = useSelector((state) => state.ticket || {}); // Ensure ticket is defined
  const { list: statusList, loading: statusLoading } = useSelector((state) => state.ticketStatus || {});

  // Updated rejection reasons as per your request
  const rejectionReasons = [
    { id: 1, name: "Photo does not meet the requirements. Please upload a clear, recent picture." },
    { id: 2, name: "The payment slip is unclear. Please upload a readable image of the slip." },
    { id: 3, name: "The payment slip does not match your registration. Please check and re-upload the correct slip." },
    { id: 4, name: "Payment not completed. Please verify and upload the payment slip once done." },
    { id: 5, name: "Other" }, // Option for custom input
  ];

  useEffect(() => {
    dispatch(loadAllTicketStatus());
    if (open) {
      dispatch(loadSingleTicket(ticketId));
    }
  }, [dispatch, ticketId, open]);

  const showModal = () => setOpen(true);

  const handleOk = async () => {
    const values = { ticketStatusId };
    
    if (parseInt(ticketStatusId) === 3) {
      // If rejected, check if "Other" is selected and add rejection reason or the custom one.
      if (rejectionReason === "Other" && otherReason.trim()) {
        values.rejectionReason = otherReason; // Set custom reason
      } else {
        values.rejectionReason = rejectionReason; // Use the selected reason
      }
    }

    const response = await dispatch(updateTicket({ id: ticketId, values }));
    if (response.payload.message === "success") {
      dispatch(loadSingleTicket(ticketId));
      setOpen(false);
      setTicketStatusId("");
      setRejectionReason("");
      setOtherReason(""); // Clear the custom input
      window.location.reload(); // Refresh the page
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setTicketStatusId("");
    setRejectionReason("");
    setOtherReason(""); // Clear the custom input
  };

  return (
    <UserPrivateComponent permission={"update-ticket"}>
      <Button className="updateBtn" type="primary" onClick={showModal}>
        Update Status
      </Button>

      <Modal
        title="Update Ticket Status"
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <Select
          loading={statusLoading}
          onChange={(value) => {
            setTicketStatusId(value);
            if (parseInt(value) !== 3) {
              setRejectionReason(""); // Clear rejection reason if status is not Rejected
              setOtherReason(""); // Clear custom input if status is not Rejected
            }
          }}
          value={ticketStatusId || ticket?.ticketStatus?.id || ""}
          placeholder="Select Ticket Status"
        >
          {statusList.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.ticketStatusName}
            </Select.Option>
          ))}
        </Select>

        {/* Show rejection reason dropdown if status is Rejected (3) */}
        {parseInt(ticketStatusId) === 3 && (
          <>
            <Select
              style={{ marginTop: "16px" }}
              placeholder="Please select rejection reason"
              onChange={setRejectionReason}
              value={rejectionReason || undefined}  // Ensure value is either empty or undefined
            >
              {rejectionReasons.map((reason) => (
                <Select.Option key={reason.id} value={reason.name}>
                  {reason.name}
                </Select.Option>
              ))}
            </Select>

            {/* Show input field for custom reason when "Other" is selected */}
            {rejectionReason === "Other" && (
              <Input
                style={{ marginTop: "16px" }}
                placeholder="Please specify rejection reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            )}
          </>
        )}
      </Modal>
    </UserPrivateComponent>
  );
};

export default TicketStatusUpdate;
