import { Input } from "antd";
import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useDispatch } from "react-redux";
import "./search.css"

export default function CrmSearch({ paginatedThunk }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const onSearchChange = (e) => {
    setInputValue(e.target.value);
  };
  const onSearch = () => {
    if (inputValue !== "") {
      dispatch(paginatedThunk({ query: "search", key: inputValue }));
    }
  };
  return (
    <Input.Search
      className='md:w-[350px] w-[220px]'
      placeholder='Write ticket id here'
      onChange={onSearchChange}
      onSearch={onSearch}
      enterButton={
        <button
          className='w-[35px] h-[35px] text-white rounded-r infineneBtn '
          onChange={onSearch}
        >
          <BiSearch className='w-full' />
        </button>
      }
    />
//     <div style={{ textAlign: 'center', fontSize: '2.5em', fontWeight: 'bold' }}>
//     <h1>UMT 27th Convocation Entry System</h1>
// </div>
  );
}
