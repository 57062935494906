import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ticketModule.css";
import getUserFromToken from "../../../utils/getUserFromToken";
import { useSelector, useDispatch } from "react-redux";
import { addSingleTicket } from "../../../redux/rtk/features/crm/ticket/ticketSlice";
import { useNavigate } from "react-router-dom";
import fileConfig from "../../../utils/fileConfig";
import moment from "moment";
import axios from "axios";

const { Dragger } = Upload;
const { Option } = Select;

const CreateTicketForm = () => {
  const { loading: tLoading } = useSelector((state) => state.ticket);
  const dispatch = useDispatch();
  const userId = getUserFromToken();
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [usertype, setUserType] = useState(0);
  const [departmentId, setDepartmentId] = useState("");
  const [description, setDescription] = useState("");
  const [charCount, setCharCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerEmail = async () => {
      try {
        const response = await axios.get(`/customer/${userId}`);
        setEmail(response.data.email);
        setUserType(parseInt(response.data.usertype, 10));
        setDepartmentId(response.data.departmentId);
        form.setFieldsValue({ email: response.data.email });
      } catch (error) {
        console.error("Error fetching customer email:", error);
      }
    };

    fetchCustomerEmail();
  }, [userId, form]);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("customerId", parseInt(userId));
      formData.append("email", values.email);
      formData.append("subject", values.subject);
      formData.append("description", description);
      formData.append("ticketCategoryId", 5);
      formData.append("ticketDate", moment().format("YYYY-MM-DD"));
      formData.append("submissionDate", moment().format("YYYY-MM-DD"));
      formData.append("usertype", usertype);
      formData.append("departmentId", departmentId);
      formData.append("postFor", values.postFor);

      if (values.files) {
        if (fileConfig() !== "laravel") {
          for (let i = 0; i < values.files.length; i++) {
            formData.append("files", values.files[i].originFileObj);
          }
        } else {
          for (let i = 0; i < values.files.length; i++) {
            formData.append("files[]", values.files[i].originFileObj);
          }
        }
      }

      const resp = await dispatch(addSingleTicket(formData));

      if (resp.payload.message === "success") {
        form.resetFields();
        navigate(`/support/ticket`);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDescriptionChange = (content, delta, source, editor) => {
    const text = editor.getText();
    if (text.length <= 2000) {
      setDescription(content);
      setCharCount(text.length);
    } else {
      message.warning("Character limit reached (2000 characters)");
    }
  };

  const handleUpload = (files) => {
    message.success(`File added successfully`);
  };

  const today = moment();
  const postForOptions = [
    { label: "Public", value: 1 },
    { label: "Participants", value: 2 },
    { label: "Faculty/Staff", value: 3 },
  ];

  return (
    <div className="max-w-screen-md mx-auto p-5 mt-1">
      <div className="text-center mb-8">
        <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight infinenTextColor">
          CREATE A POST
        </h3>
      </div>

      <Form
        name="ticketForm"
        className="w-full bg-gray-50 p-5 rounded-lg"
        onFinish={(values) => onFinish({ ...values, description })}
        form={form}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email Address"
          tooltip="We'll use this email to contact you"
          initialValue={email}
        >
          <Input placeholder="youremail@something.com" value={email} disabled />
        </Form.Item>
        <Form.Item
          name="postFor"
          label="Post For"
          rules={[
            {
              required: true,
              message: "Please select who this post is for",
            },
          ]}
        >
          <Select placeholder="Select target audience">
            {postForOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please enter the subject",
            },
          ]}
        >
          <Input placeholder="Please write subject" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <ReactQuill
            theme="snow"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Enter the post content"
          />
          <div style={{ textAlign: "right", marginTop: "5px" }}>
            {charCount}/3000 characters
          </div>
        </Form.Item>

        <Form.Item name="ticketDate" hidden initialValue={today}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item
          name="files"
          label="Attachments"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
        >
          <Dragger
            accept=".png,.jpg,.jpeg"
            multiple={true}
            beforeUpload={() => false}
            onChange={handleUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Drag and drop files or click to select files
            </p>
            <p className="ant-upload-hint">
              Supported files types: PNG, JPG, JPEG
            </p>
          </Dragger>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={tLoading}
          >
            Create Post
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateTicketForm;
