import { Button, Result } from "antd";
import { Link } from "react-router-dom";
const SuccessPageAdmin = () => (
	<Result
		status='success'
		title={
			<div className=' items-center'>
				<h1 className=' text-2xl text-gray-800 font-poppins'>
					Congratulations this user has been successfully added <br></br>a welcome email sent to that user with Login Credentials{" "}
				</h1>

				<h2 className=' text-indigo-600 font-poppins font-semibold'>
					Ask him/her to check email
				</h2>
			</div>
		}
		subTitle='Usually it takes 5-10 minutes to receive the email. If he/she does not receive the email, please ask him/her to check their spam folder.'
		extra={[
			<Link to='/admin/setup' >
				<Button type='primary' key='loginbtn'>
					Go to Dashboard
				</Button>
			</Link>,
		]}
	/>
);
export default SuccessPageAdmin;
