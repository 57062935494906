import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Table,Button } from "antd";

import { loadAllCustomers,updateCustomerStatus } from "../../redux/rtk/features/user/userSlice";
// import ViewBtn from "../Buttons/ViewBtn";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";

 // Define columns for the customer table
 const columns = (handleStatusToggle) => [
  {
    id: 1,
    title: "User ID",
    dataIndex: "id",
    key: "id",
  },
  {
    id: 2,
    title: "Full Name",
    key: "fullName",
    render: ({ firstName, lastName }) =>
      (firstName + " " + lastName).toUpperCase(),
  },
  {
    id: 3,
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    id: 4,
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    id: 5,
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "jobTitle",
  },
  {
    id: 6,
    title: "Role",
    dataIndex: "roleId",
    key: "roleId",
  },
  {
    id: 7,
    title: "Action",
    dataIndex: "id",
    key: "action",
    render: (id, record) => (
      <div className="flex justify-start items-center gap-4">
        {/* Toggle Active/Inactive Button */}
        <Button
          type={record.status ? "primary" : "default"}
          danger={!record.status}
          onClick={() => handleStatusToggle(id, record.status)}
        >
          {record.status ? "Deactivate" : "Activate"}
        </Button>
      </div>
    ),
  },
  
];

function CustomTable() {
  const dispatch = useDispatch();
  const [columnsToShow, setColumnsToShow] = useState([]);

  // Fetch customer data from Redux store
  const { customerList, loading } = useSelector((state) => state.users);

 

  useEffect(() => {
    // Dispatch action to load all customers
    dispatch(loadAllCustomers());
    setColumnsToShow(columns(handleStatusToggle));
    // eslint-disable-next-line
  }, [dispatch,]);

  // const columnsToShowHandler = (val) => {
  //   setColumnsToShow(val);
  // };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));
  const handleStatusToggle = async (id, status) => {
    const newStatus = !status;
    const apiUrl = newStatus ? `customer/activate/${id}` : `customer/deactivate/${id}`;
    try {
      await dispatch(updateCustomerStatus({ apiUrl, id, status: newStatus }));
      // Reload the page after successful status toggle
      window.location.reload();
    } catch (error) {
      console.log("Error updating status:", error);
    }
  };
  return (
    <div className="ant-card p-4 rounded mt-5">
      <div className="flex my-2 justify-between">
        <div className="w-50">
          <h4 className="text-2xl mb-2">Users List</h4>
        </div>
        {customerList && (
          <div className="flex justify-end mr-4">
            <div className="mt-0.5">
              <CsvLinkBtn>
                <CSVLink
                  data={customerList}
                  className="btn btn-dark btn-sm"
                  style={{ marginTop: "5px" }}
                  filename="customers"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          </div>
        )}
      </div>

      {/* Table displaying customer data */}
      <Table
        columns={columnsToShow}
        dataSource={addKeys(customerList)}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default CustomTable;
