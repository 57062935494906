import { Button, Card, Form, Input, Typography, Select } from "antd";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { customerRegister } from "../../redux/rtk/features/user/userSlice";
import SuccessPageAdmin from "./Page/SuccessPageAdmin";
import { getDepartments } from "../department/departmentApis";

const { Option } = Select;

const CustomerRegisterAdmin = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [departments, setDepartments] = useState([]); // State to store department data
  const dispatch = useDispatch();
  const { Title } = Typography;
  const [form] = Form.useForm();

  useEffect(() => {
    // Fetch departments when component mounts
    const fetchDepartments = async () => {
      try {
        const data = await getDepartments();
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  const onFinish = async (values) => {
    try {
      const userTypeMapping = {
        "department head": 1,
        "coordinator": 2,
      };

      const formData = {
        ...values,
        usertype: userTypeMapping[values.usertype],
      };

      const resp = await dispatch(customerRegister(formData));
      setLoader(true);
      if (resp.payload.message === "success") {
        setLoader(false);
        setSuccess(true);
      } else if (resp.payload.message === "Email already registered") {
        setLoader(false);
        form.setFields([
          {
            name: "email",
            errors: ["This email is already registered"],
          },
        ]);
      } else {
        setLoader(false);
      }

      form.resetFields();
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoader(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center items-center h-[80vh]">
      {success ? (
        <SuccessPageAdmin />
      ) : (
        <div className="w-4/5 md:[500px]">
          <Card
            bordered={false}
            className="rounded-lg w-full max-w-[30rem] mx-auto"
          >
            <Title level={3} className="m-3 text-center mb-10">
              User Registration
            </Title>
            <Form
              form={form}
              className="m-4"
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                style={{ marginBottom: "10px" }}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input email!",
                  },
                ]}
              >
                <Input placeholder="Please Enter email of that user which you want to add " />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                name="usertype"
                rules={[
                  {
                    required: true,
                    message: "Please select user type!",
                  },
                ]}
              >
                <Select placeholder="Please select user type">
                  <Option value="department head">Department Head</Option>
                  <Option value="coordinator">Coordinator</Option>
                </Select>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                name="department"
                rules={[
                  {
                    required: true,
                    message: "Please select department!",
                  },
                ]}
              >
                <Select placeholder="Please select department">
                  {departments.map((department) => (
                     <Option key={department.id} value={department.id}>
                      {department.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item style={{ marginBottom: "10px" }} className="mb-2">
                <Button
                  onClick={() => setLoader(true)}
                  block
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loader}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CustomerRegisterAdmin;
