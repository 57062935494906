// const baseUrl = process.env.NODE_ENV === "production" 
// ? 'http://localhost:2096/' 
// : 'http://localhost:2096';

// export default baseUrl;


// sydhasn786@gmail.com
const baseUrl = process.env.NODE_ENV === "production" 
  ? 'https://conv27.umt.edu.pk' 
  : 'https://conv27.umt.edu.pk:2096';

  const apiCall = process.env.NODE_ENV === "production" 
  ? 'https://conv27.umt.edu.pk:2096' 
  : 'https://conv27.umt.edu.pk:2096';

module.exports = baseUrl;
module.exports = apiCall;

